.PageContent {
  min-height: 100vh;
  width: 100%;
  padding: 0 60px;
}

.PageMainTitle {
  margin: initial;
  white-space: nowrap;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: #000;
  margin-top: 140px;
  border-bottom: 1px solid #eee;
}

.ScheduleSettings {
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-left: 60px;

  &__label {
    margin: initial;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    text-decoration: underline;
    white-space: nowrap;
  }

  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: opacity(0.4);
  }

  &__date {
    display: block;
    text-align: center;
    padding: 0 15px;
    height: 34px;
    min-width: 160px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  &__submit {
    display: inline-block;
    white-space: nowrap;
    margin-left: 11px;
    padding: 0 40px;
    height: 34px;
    border: none;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    background-color: #3988FF;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #2d6ccc;
    }
  }
}
