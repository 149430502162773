.question-modal-dialog {
  height: 100%;
  display: grid;
  place-items: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .modal-content {
    height: fit-content;
  }
}
