.important-input {
  margin: 0 0 15px 15px !important;
}

#notification-date {
  display: flex;
  margin-left: 5px;
}

#create-notification-button {
  margin-top: 1rem;
  width: 200px;
  height: 40px;
}

.admin-notification-table {
  margin-top: 1rem;
}

.admin-notification-table th, td {
  text-align: center;
}

.admin-notification-message {
  text-align: start;
  white-space: pre-wrap;
  word-break: break-word;
}

.admin-notification-actions {
  display: flex;
  flex-direction: row;
}
