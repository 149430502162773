.direction__model__layout{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2
}
