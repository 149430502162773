@import "../constants";
.report-card {
  position: relative;
  display: flex;
  min-height: 210px;
  min-width: 320px;
  max-width: 380px;
  flex-basis: 385px;
  background: #FFFFFF;
  border: 1px solid  #AFADAD;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px;
  transition: .02s ease-in;
  &:hover{
    border-color: $mainBlue;
  }
}

.card-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.card-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 50%;
}

.student-name {
  font-size: 20px;
  line-height: 23px;
}

.report-data {
  margin-left: 10px;
  width: auto;
}

.student-birthday {
  font-size: 13px;
  line-height: 15px;
  color: #747474;
}

.spend-time, .task-progress-text {
  font-size: 17px;
  line-height: 20px;
}
.task-progress-text {
  max-height: 80px;
  overflow: hidden;
  word-wrap:anywhere ;

}
.spend-time{
  margin-top: 15px;
}
.task-progress-text--expand_true{
  max-height: 100% !important;
}
.toggle-expand-text{
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  color:$mainBlue;
  transition: color 0.2s ease-in;
  &:hover{
    text-decoration: underline;
  }
}

.edit-menu-btn{
  position: absolute;
  font-size: 19px;
  right: 15px;
}
.edit-buttons{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 10px;
  row-gap: 5px;
  font-size: 13px;
}
.edit-btn{
  display: flex;
  justify-content: flex-start;
  transition: color .2s ease-in;
  &:hover{
    color: $mainBlue;
  }
}
.btn{
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;
}
