.deadlines-button {
    width: 100%;
    margin: 5px 0;
}

.show-deadlines{
    max-height: 100vh;
}

.hide-deadlines{
    max-height: 0;
    overflow: hidden;
}

.deadlines{
    transition: max-height 0.2s ease;
}