.wrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 900px;
  min-height: 300px;
  max-height: 90vh;
  box-sizing: border-box;
}
