%before-item {
  display: block;
  content: '';
  width: 12px;
  height: 18px;
}

.direction__action__slack{
    background-image: url("/images/slak-logo.png") !important;
    background-size: 105%;
    background-position: center;

    &:before {
      @extend %before-item
    }
}
.direction__action__google-table{
    background-image: url("/images/google-table-logo.png") !important;
    background-size: 156%;
    background-position: -5px -2px;

    &:before {
      @extend %before-item;
    }
}

.container-loader {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translate(-50%, -0);
}

.btn-custom-edit{
  padding: 0;
}

