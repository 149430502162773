.ScheduleTable {
  border-collapse: collapse;
  border-style: hidden;
  width: 90%;
  min-height: 400px;
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 80px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.08));
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;

  &__header {
    background-color: #CDDFFF;
    border-radius: 10px 10px 0px 0px;

    th:first-child {
      border-top-left-radius: 10px;
    }
    th:last-child {
      border-top-right-radius: 10px;
    }
    
    .ScheduleTable__row {
      .ScheduleTable__data-title {
        padding: 17px 5px;
        vertical-align: middle;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &__body {
    tr:nth-child(odd) {
      background-color: #F3F3F3;
    }
    tr:nth-child(even) {
      background-color: #E7E7E7;
    }
  }

  &__footer {
    background-color: #CDDFFF;
    height: 65px;

    td:first-child {
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-bottom-right-radius: 10px;
    }

    .ScheduleTable__data {
      height: 65px;
    }
  }

  &__row {
    :not(:first-of-type) {
      vertical-align: top;
    }
    &_centered {
      th, td {
        text-align: center;
      }
    }
  }

  &__data,
  &__data-title {
    border-right: 1px solid #C4C4C4;
    &:last-child {
      border-right: none;
    }
  }

  &__data {
    padding: 15px;
    width: calc(100% / 8);
    white-space: nowrap;
    overflow: visible;
  }

  &__zoom-account {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 5px;
    background-color: #fff;
    border: 1px solid #BFBFBF;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}