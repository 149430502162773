.review-options-block {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}

#review-mentor-select {
  width: 200px;
}

#review-course-select {
  margin-left: 1rem;
  width: 200px;
}

#review-module-select {
  margin-left: 1rem;
  width: 350px;
}

#review-date {
  margin-left: 1rem;
  width: 200px;
}
