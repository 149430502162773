@import "constants";
.report-wrap{
  flex: 1 0 auto;
}

.report-block {
  background: #fff;
  border-radius: 10px;
  margin-top: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0 , 0.25);
  padding-bottom: 15px;
}

.header-report {
  background: $lightBlue;
  color: #0f0f0f;
  font-size: 20px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  height: 60px;
}

.report-filters {
  background: #fff;
  padding:20px;
}

.report-list {
  background: $lightBlue;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 20px 20px 20px;
}
.pagination-wrapper{
  display: flex;
  justify-content: center;
}
